import React from 'react';

const User = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1844_10851)">
        <rect width="32" height="32" rx="16" fill="white" />
        <circle cx="16" cy="12" r="6" fill="#A8B2BE" />
        <circle cx="16" cy="30" r="10" fill="#A8B2BE" />
      </g>
      <defs>
        <clipPath id="clip0_1844_10851">
          <rect width="32" height="32" rx="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default User;
