export enum MaterialLevel {
  LEVEL0 = '기타',
  LEVEL1 = '개념/연산',
  LEVEL2 = '기본 유형',
  LEVEL3 = '응용/심화',
  LEVEL4 = '킬러 유형',
  MIDTERM_TEST = '중간기출',
  FINAL_TEST = '기말기출',
}

export enum MaterialLevelEng {
  LEVEL0 = 'level0',
  LEVEL1 = 'level1',
  LEVEL2 = 'level2',
  LEVEL3 = 'level3',
  LEVEL4 = 'level4',
}

export enum StudyContentType {
  MY_STUDY_MATERIAL = 'my-study-materials',
  MY_ASSIGNMENT = 'my-assignments',
  MY_TEST = 'my-tests',
  MY_WRONG_TEST = 'my-wrong-tests',
  MY_LEVEL_TEST = 'my-level-tests',
}

export const TEST_STUDY_CONTENT_TYPE = new Set([
  StudyContentType.MY_TEST,
  StudyContentType.MY_WRONG_TEST,
  StudyContentType.MY_LEVEL_TEST,
]);

export type StudyContentPageType =
  | StudyContentType.MY_ASSIGNMENT
  | StudyContentType.MY_STUDY_MATERIAL
  | StudyContentType.MY_TEST
  | 'my-study-resources';

export type TotalCourse =
  | ElementarySchoolMathCourse
  | MiddleSchoolMathCourse
  | HighSchoolMathCourse
  | EnglishCourse
  | EtcCourse;

export type MathCourse =
  | ElementarySchoolMathCourse
  | MiddleSchoolMathCourse
  | HighSchoolMathCourse;

export enum ElementarySchoolMathCourse {
  PRIMARY1_1 = '초1-1',
  PRIMARY1_2 = '초1-2',
  PRIMARY2_1 = '초2-1',
  PRIMARY2_2 = '초2-2',
  PRIMARY3_1 = '초3-1',
  PRIMARY3_2 = '초3-2',
  PRIMARY4_1 = '초4-1',
  PRIMARY4_2 = '초4-2',
  PRIMARY5_1 = '초5-1',
  PRIMARY5_2 = '초5-2',
  PRIMARY6_1 = '초6-1',
  PRIMARY6_2 = '초6-2',
  PRIMARY = '초등',
}
export enum MiddleSchoolMathCourse {
  MIDDLE1_1 = '중1-1',
  MIDDLE1_2 = '중1-2',
  MIDDLE2_1 = '중2-1',
  MIDDLE2_2 = '중2-2',
  MIDDLE3_1 = '중3-1',
  MIDDLE3_2 = '중3-2',
  MIDDLE = '중등',
}
export enum HighSchoolMathCourse {
  MATH_COMMON_1 = '공통수학1',
  MATH_COMMON_2 = '공통수학2',
  MATH_PREVIOUS = '수학(상)',
  MATH_AFTER = '수학(하)',
  MATH_1 = '수학1',
  MATH_2 = '수학2',
  CALCULUS = '미적분',
  STATISTICS = '확률과 통계',
  GEOMETRY = '기하',
  HIGH = '고등',
}
export enum EnglishCourse {
  SH_PREP = 'SH Prep',
  AMC = 'AMC',
  AP_CALCULUS_AB = 'AP Calculus AB',
}
export enum EtcCourse {
  ETC = '기타',
}
export enum MaterialType {
  CONCEPT = '개념연산서',
  BASIC = '기본유형서',
  PRACTICAL = '응용심화서',
  ADVANCED = '킬러유형서',
  TEXTBOOK = '교과서',
  HANDOUT = '학교프린트',
  SCHOOL_TEST = '학교기출문제',
  SCHOOL_SUB_MATERIAL = '학교부교재',
  MOCK_TEST = '모의고사',
  LEVEL_TEST = '진단테스트',
  CHAPTER_TEST = '단원테스트',
  TOTAL_TEST = '총괄테스트',
  DAILY_TEST = '데일리테스트',
  REVIEW_TEST = '복습테스트',
  ETC = '기타',
}
export enum SubjectEnum {
  MATHEMATICS = '수학',
  ENGLISH = '영어',
}

export enum StudyContentListOrderBy {
  ID = 'id',
  ID_DES = '-id',
  BOOK_MARK_UPDATED_AT = 'bookMarkUpdatedAt',
  BOOK_MARK_UPDATED_AT_DES = '-bookMarkUpdatedAt',
  IS_BOOK_MARKED = 'isBookmarked',
  IS_BOOK_MARKED_DES = '-isBookmarked',
}
