import React from 'react';
import * as S from '@styles/common/IconContainer.style';

const IconContainer = ({
  icon,
  size,
  bgColor = 'var(--gray-100)',
  border = 'none',
  borderRadius = null,
  onClick = null,
  disabled = false,
}: {
  icon: React.ReactNode;
  size: 'S' | 'M';
  bgColor?: string;
  border?: string;
  borderRadius?: string;
  onClick?: () => void;
  disabled?: boolean;
}) => {
  return (
    <S.IconContainer
      bgColor={bgColor}
      size={size}
      border={border}
      borderRadius={borderRadius}
    >
      <div className="icon">{icon}</div>
      <button className="cover" onClick={onClick} disabled={disabled}></button>
    </S.IconContainer>
  );
};

export default IconContainer;
