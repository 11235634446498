import Link from 'next/link';
import React, { useEffect, useCallback, useState, ReactElement } from 'react';
import {
  loginUserInfoState,
  selectedStudentIdAtom,
  useStudentInfoObjectState,
  useStudentListState,
} from '@atoms/states';
import { getStudentList } from '@apis/api/dashboard';
import { useRouter } from 'next/router';
import { useRecoilState } from 'recoil';
import * as amplitude from '@amplitude/analytics-browser';
import { useEventPropertiesStore } from '@store/common';
import * as S from '@styles/common/nav/Gnb.style';
import { useTranslation } from 'next-i18next';
import OpenedBookIcon from '@asset/svg/OpenedBookIcon';
import AccountBalanceIcon from '@asset/svg/AccountBalanceIcon';
import PersonIcon from '@asset/svg/PersonIcon';
import { StudentPage, TeacherPage } from '@asset/enum/page';
import {
  useSelectedStudentInfoQuery,
  useUserInfoQuery,
} from '@apis/query/user';
import {
  useGnbStudentListFilteringQueryStore,
  useMyClassFilteringQueryStore,
  useMyClassWeeklyGoalFilteringQueryStore,
} from '@store/filteringQueryStore';
import { STUDENT_STUDY_CONTENT_URL_PREFIX } from 'routes/studyContent';
import _ from 'lodash';
import SelectStudentDropdown, {
  StudentGroupType,
} from '../dropDown/SelectStudentDropDown';
import { MANAGE_COURSE_DETAIL_URL_PREFIX } from 'routes/roadmap';
import Logout from '@asset/svg/Logout';
import { logoutAndResetUserInfo } from '@asset/function/auth';
import { UserType } from '@asset/enum/UserEnum';
import { FilledBarChartIcon } from '@asset/svg/BarChartIcon';
import { FilledCalendarTodayIcon } from '@asset/svg/CalendarTodayIcon';
import { FilledHomeIcon } from '@asset/svg/HomeIcon';
import { FilledSchoolIcon } from '@asset/svg/SchoolIcon';
import { FilledTrophyIcon } from '@asset/svg/TrophyIcon';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { MenuBoxStyle } from '@styles/common/box/menuBoxStyles';
import User from '@asset/svg/User';
import BasicMenu from '../list/BasicMenu';

interface MenuObj {
  url: string;
  title: string;
  urlKeyword: string[];
  pageType?: string;
  icon?: ReactElement;
}

const Gnb = () => {
  const router = useRouter();
  const { t, ready } = useTranslation('gnb');
  const [, setUser] = useRecoilState(loginUserInfoState);
  const [selectedStudentId, setSelectedStudentId] = useRecoilState(
    selectedStudentIdAtom,
  );
  const [studentList, setStudentList] = useStudentListState();
  const [, setStudentInfoObject] = useStudentInfoObjectState();
  const [pickedMenu, setPickedMenu] = useState(null);
  const { data: user } = useUserInfoQuery();
  const { data: selectedStudent } = useSelectedStudentInfoQuery();
  const { resetUrlQueryObj: resetMyClassUrlQueryObj } =
    useMyClassFilteringQueryStore();
  const { resetUrlQueryObj: resetMyClassWeeklyGoalUrlQueryObj } =
    useMyClassWeeklyGoalFilteringQueryStore();
  const { filteringInfo, setFilteringInfo } =
    useGnbStudentListFilteringQueryStore();
  const { properties } = useEventPropertiesStore();
  const [menuItemList, setMenuItemList] = useState<MenuObj[]>([]);
  const [openMyPageBox, setOpenMyPageBox] = useState<boolean>(false);
  const [openStudentNameDropdown, setOpenStudentNameDropdown] =
    useState<boolean>(false);

  const teacherMenuItems: MenuObj[] = [
    {
      url: `/manage/${TeacherPage.CLASS}/main/`,
      title: 'gnb:teacher.manageStudent',
      urlKeyword: [
        'manage/dashboard',
        `manage/student-study-content`,
        `manage/student`,
        `manage/study-status-list/last`,
        `manage/study-status-list/total`,
        `manage/checking`,
        `manage/${TeacherPage.CLASS}`,
      ],
      pageType: 'manage_student',
      icon: <PersonIcon color={'var(--gray-60)'} width="1rem" height="1rem" />,
    },
    {
      url: '/manage/material-list/?isHidden=false',
      title: 'gnb:teacher.manageTextbook',
      urlKeyword: ['/manage/material', '/manage/course'],
      pageType: 'manage_study_material',
      icon: (
        <OpenedBookIcon color={'var(--gray-60)'} width="1rem" height="1rem" />
      ),
    },
    {
      url: '/manage/teacher-list',
      title: 'gnb:teacher.management',
      urlKeyword: ['manage/teacher'],
      pageType: 'manage_teacher',
      icon: (
        <AccountBalanceIcon
          color={'var(--gray-60)'}
          width="1rem"
          height="1rem"
        />
      ),
    },
  ];

  const studentMenuItems: MenuObj[] = [
    {
      url: '/dashboard',
      title: 'gnb:student.home',
      urlKeyword: [],
      pageType: 'main_dashboard',
      icon: (
        <FilledHomeIcon color={'var(--gray-60)'} width="1rem" height="1rem" />
      ),
    },
    {
      url: `/dashboard/study/${StudentPage.MY_ASSIGNMENTS}`,
      title: 'gnb:student.learn',
      urlKeyword: [
        StudentPage.MY_ASSIGNMENTS,
        StudentPage.MY_TESTS,
        StudentPage.MY_STUDY_MATERIALS,
        StudentPage.MY_FEEDBACKS,
        StudentPage.MY_QUESTIONS,
      ],
      pageType: 'my_assignments',
      icon: (
        <FilledSchoolIcon color={'var(--gray-60)'} width="1rem" height="1rem" />
      ),
    },
    {
      url: `/dashboard/plan/${StudentPage.MY_PLANS}`,
      title: 'gnb:student.plan',
      urlKeyword: [StudentPage.MY_PLANS, StudentPage.MY_SCHEDULE],
      pageType: 'my_plans',
      icon: (
        <FilledCalendarTodayIcon
          color={'var(--gray-60)'}
          width="1rem"
          height="1rem"
        />
      ),
    },
    {
      url: `/dashboard/analysis/${StudentPage.MY_STUDY_RECORDS}`,
      title: 'gnb:student.analytics',
      urlKeyword: [StudentPage.MY_STUDY_RECORDS, StudentPage.LEVEL_TEST],
      pageType: 'my_study_records',
      icon: (
        <FilledBarChartIcon
          color={'var(--gray-60)'}
          width="1rem"
          height="1rem"
        />
      ),
    },
    {
      url: `/dashboard/${StudentPage.MY_RANKING}`,
      title: 'gnb:student.ranking',
      urlKeyword: [StudentPage.MY_RANKING],
      pageType: 'ranking',
      icon: (
        <FilledTrophyIcon color={'var(--gray-60)'} width="1rem" height="1rem" />
      ),
    },
  ];

  const isPickedUrl = (element) => {
    if (element.url === pickedMenu) return true;
    else {
      for (let index = 0; index < element?.urlKeyword?.length; index++) {
        if (pickedMenu?.includes(element?.urlKeyword[index])) return true;
      }
    }
    return false;
  };

  useEffect(
    function () {
      if (router.isReady) {
        setPickedMenu(router.asPath);
        if (user?.type === UserType.TEACHER) {
          if (router.pathname.startsWith('/manage/dashboard/')) {
            const studentId = router.asPath.split('/')[4]
              ? parseInt(router.asPath.split('/')[4])
              : router.query.type
              ? parseInt(router.query.type[1])
              : selectedStudentId
              ? selectedStudentId
              : null;
            if (!_.isNaN(studentId) && !_.isNil(studentId)) {
              if (selectedStudentId !== studentId)
                setSelectedStudentId(studentId);
            } else {
              router.replace('/');
            }
          } else if (
            router.pathname.startsWith(STUDENT_STUDY_CONTENT_URL_PREFIX) ||
            router.pathname.startsWith(MANAGE_COURSE_DETAIL_URL_PREFIX)
          ) {
            const studentId = router.query.studentId
              ? parseInt(router.query.studentId as string)
              : null;
            if (
              (!selectedStudent || selectedStudent?.id !== studentId) &&
              studentId
            )
              setSelectedStudentId(studentId);
          }
        }
      }
    },
    [router, user],
  );

  useEffect(() => {
    if (user?.type === UserType.TEACHER) {
      setMenuItemList(teacherMenuItems);
    } else if (user?.type === UserType.STUDENT) {
      setMenuItemList(studentMenuItems);
    } else {
      setMenuItemList([]);
    }
  }, [user]);

  const getStudentListData = useCallback(async () => {
    if (user?.id && user?.type === UserType.TEACHER) {
      const studentListResponse = await getStudentList({
        pageSize: 10000,
        status: '재원',
        teacherId:
          filteringInfo &&
          filteringInfo[user.id] &&
          filteringInfo[user.id] === StudentGroupType.MY_STUDENTS
            ? user.id
            : null,
      });
      setStudentList(studentListResponse.students);
    }
  }, [user, filteringInfo]);

  useEffect(() => {
    getStudentListData();
  }, [getStudentListData]);

  return (
    <S.GnbLayout display={user?.type === UserType.TEACHER ? 'flex' : 'grid'}>
      <S.GnbMainLogoWrapper>
        <div className="object-container">
          <Link href={'/'}>
            <a></a>
          </Link>
          <object
            data="/common/logo/gnb_white_logo.svg"
            type={'image/svg+xml'}
          />
        </div>
      </S.GnbMainLogoWrapper>
      <S.GnbMenuItemContainer
        justifyContent={user?.type === UserType.STUDENT ? 'center' : 'start'}
        gap={user?.type === UserType.STUDENT ? '1rem' : '1.5rem'}
      >
        {menuItemList.map((element) => {
          return ready ? (
            <Link key={element.url} href={element.url}>
              <a
                onClick={() => {
                  const logText = `click_main_nav_${element?.pageType}`;
                  amplitude.track(logText, properties);
                }}
                className={`${isPickedUrl(element) ? 'picked' : ''} ${
                  ['Leaderboard'].includes(t(element.title)) ? '!hidden' : ''
                }`}
              >
                {element.icon ?? element.icon}
                {t(element.title)}
              </a>
            </Link>
          ) : (
            ''
          );
        })}
      </S.GnbMenuItemContainer>

      <S.GnbUserInfoSection>
        {user?.type === UserType.TEACHER && studentList ? (
          <SelectStudentDropdown
            studentList={studentList}
            studentGroupFiltering={
              filteringInfo && filteringInfo[user.id]
                ? filteringInfo[user.id]
                : StudentGroupType.ALL_STUDENTS
            }
            onSelectFiltering={(filtering) =>
              setFilteringInfo({ [user.id]: filtering })
            }
          />
        ) : user?.type === UserType.PARENT && studentList ? (
          <S.StudentListDropDownContainer
            onClick={() => {
              setOpenStudentNameDropdown((prev) => !prev);
            }}
          >
            <p>{selectedStudent?.name}</p>
            <button type="button">
              <img src="/common/icon/gnb/list_arrow_icon.svg"></img>
              {openStudentNameDropdown && (
                <BasicMenu
                  items={studentList.map((obj) => {
                    return {
                      id: obj.id,
                      content: obj.name,
                      selected: obj.id === selectedStudent?.id,
                    };
                  })}
                  customStyles={{
                    top: '2.5rem',
                    right: '2.5rem',
                  }}
                  onClickAway={() => {
                    setOpenStudentNameDropdown(false);
                  }}
                  onClickItem={async (selectedItem) => {
                    setOpenStudentNameDropdown(false);
                    setSelectedStudentId(Number(selectedItem.id));
                    router.query.studentId = selectedItem.id as string;
                    router.replace(router);
                  }}
                />
              )}
            </button>
          </S.StudentListDropDownContainer>
        ) : (
          <></>
        )}
        {user?.type === UserType.STUDENT ? (
          <button
            className="relative"
            onClick={() => setOpenMyPageBox(!openMyPageBox)}
          >
            <User />
            {openMyPageBox ? (
              <ClickAwayListener
                onClickAway={(): void => {
                  setOpenMyPageBox(false);
                }}
              >
                <MenuBoxStyle
                  position="absolute"
                  top="2.5rem"
                  right="0"
                  width="max-content"
                >
                  <span
                    onClick={() =>
                      router.push(
                        `/dashboard/my-page/${StudentPage.MY_INFORMATION}`,
                      )
                    }
                  >
                    {ready ? t('gnb:myInfo.myInfo') : ''}
                  </span>
                  <span
                    onClick={async () => {
                      await logoutAndResetUserInfo();
                      setUser(null);
                      setStudentInfoObject(null);
                    }}
                  >
                    {ready ? t('gnb:myInfo.logOut') : ''}
                  </span>
                </MenuBoxStyle>
              </ClickAwayListener>
            ) : (
              <></>
            )}
          </button>
        ) : (
          <button
            onClick={async () => {
              await logoutAndResetUserInfo();
              setUser(null);
              setSelectedStudentId(null);
              setStudentList(null);
              setStudentInfoObject(null);
              resetMyClassUrlQueryObj();
              resetMyClassWeeklyGoalUrlQueryObj();
            }}
          >
            <Logout
              width={'1.5rem'}
              height={'1.5rem'}
              color={'var(--gray-100)'}
            />
          </button>
        )}
      </S.GnbUserInfoSection>
    </S.GnbLayout>
  );
};

export default Gnb;
