import { ArrowDropUpIcon, ArrowDropDownIcon } from '@asset/svg/ArrowDropIcon';
import MoreIcon from '@asset/svg/MoreIcon';
import React, { useState } from 'react';
import IconContainer from '../IconContainer';
import { useRouter } from 'next/router';
import * as S from '@styles/common/nav/MobileTopNavBar.style';
import {
  selectedStudentIdAtom,
  useStudentInfoObjectState,
  useStudentListState,
} from '@atoms/states';
import { useRecoilState } from 'recoil';
import { usersLogOut } from '@apis/api/auth';
import { UserType } from '@asset/enum/UserEnum';
import { setUser } from '@sentry/nextjs';
import { LoginPageType } from 'constants/enum';
import { getLoginPageUrlObject } from 'routes/account';
import * as amplitude from '@amplitude/analytics-browser';
import cookieCutter from 'cookie-cutter';
import Dialog from '../modal/Dialog';
import {
  useSelectedStudentInfoQuery,
  useUserInfoQuery,
} from '@apis/query/user';
import BasicMenu from '../list/BasicMenu';

const MobileTopNavBar = ({ backgroundColor }) => {
  const router = useRouter();
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [openStudentNameDropdown, setOpenStudentNameDropdown] =
    useState<boolean>(false);
  const [studentList, setStudentList] = useStudentListState();
  const [openLogoutDialog, setOpenLogoutDialog] = useState<boolean>(false);
  const [, setSelectedStudentId] = useRecoilState(selectedStudentIdAtom);
  const [, setStudentInfoObject] = useStudentInfoObjectState();
  const { data: selectedStudent } = useSelectedStudentInfoQuery();
  const { data: user } = useUserInfoQuery();

  const studentId =
    user?.type === UserType.STUDENT ? user.id : selectedStudent?.id;
  const studentName =
    user?.type === UserType.STUDENT ? user.name : selectedStudent?.name;

  return (
    <S.Layout backgroundColor={backgroundColor}>
      <S.StudentDropDownWrapper
        onClick={() => {
          if (studentList?.length > 1)
            setOpenStudentNameDropdown((prev) => !prev);
        }}
      >
        <p>{studentName}</p>
        {studentList?.length > 1 ? (
          openStudentNameDropdown ? (
            <ArrowDropUpIcon width="1rem" height="1rem" />
          ) : (
            <ArrowDropDownIcon width="1rem" height="1rem" />
          )
        ) : (
          <></>
        )}
        {openStudentNameDropdown && studentList.length > 1 ? (
          <BasicMenu
            items={studentList.map((obj) => {
              return {
                id: obj.id,
                content: obj.name,
                selected: obj.id === studentId,
              };
            })}
            customStyles={{
              top: '2.5rem',
              left: '0',
            }}
            onClickAway={() => {
              setOpenStudentNameDropdown(false);
            }}
            onClickItem={async (selectedItem) => {
              setOpenStudentNameDropdown(false);
              setSelectedStudentId(Number(selectedItem.id));
              router.query.studentId = selectedItem.id as string;
              router.replace(router);
            }}
          />
        ) : (
          <></>
        )}
      </S.StudentDropDownWrapper>
      <S.DropdownMenuWrapper>
        <IconContainer
          icon={
            <MoreIcon
              width="1.5rem"
              height="1.5rem"
              color="var(--gray-10)"
              classes="rotate-90"
            />
          }
          bgColor="inherit"
          size={'M'}
          onClick={() => {
            setOpenMenu(true);
          }}
        ></IconContainer>
        {openMenu && (
          <BasicMenu
            items={[
              {
                id: 'logout',
                content: '로그아웃',
                selected: false,
              },
            ]}
            customStyles={{
              top: '2.5rem',
            }}
            onClickAway={() => {
              setOpenMenu(false);
            }}
            onClickItem={async (selectedItem) => {
              if (selectedItem.id === 'logout') {
                setOpenMenu(false);
                setOpenLogoutDialog(true);
              }
            }}
          />
        )}
      </S.DropdownMenuWrapper>
      {openLogoutDialog && (
        <Dialog
          title={'로그아웃'}
          content={['로그아웃 하시겠습니까?']}
          buttons={[
            {
              color: 'gray',
              assetType: 'Secondary',
              content: '취소',
              buttonType: 'button',
              onClickEvent: () => setOpenLogoutDialog(false),
            },
            {
              color: 'red',
              assetType: 'Primary',
              content: '로그아웃',
              buttonType: 'button',
              onClickEvent: async () => {
                await usersLogOut();
                router.replace(
                  getLoginPageUrlObject({
                    loginPageType:
                      user?.type === UserType.PARENT
                        ? LoginPageType.PARENTS
                        : LoginPageType.NORMAL,
                  }),
                );
                setUser(null);
                setSelectedStudentId(null);
                setStudentList(null);
                setStudentInfoObject(null);
                amplitude.reset();
                cookieCutter.set('NEXT_LOCALE', '', {
                  path: '/',
                });
              },
            },
          ]}
        />
      )}
    </S.Layout>
  );
};

export default MobileTopNavBar;
