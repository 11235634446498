import styled from 'styled-components';
import tw from 'twin.macro';

export const IconContainer = styled.div`
  & {
    ${tw`relative`}
    width: ${(props) => (props.size === 'S' ? '1.5rem' : '2rem')};
    height: ${(props) => (props.size === 'S' ? '1.5rem' : '2rem')};

    .icon {
      ${tw`absolute top-0 left-0 flex items-center justify-center w-full h-full p-1 rounded-lg`}
      background-color: ${(props) => props.bgColor};
    }
    button.cover {
      ${tw`absolute top-0 left-0 w-full h-full bg-transparent rounded-lg`} border: ${(
        props,
      ) => props.border};
      border-radius: ${(props) => props.borderRadius};
      &:hover:not(:disabled) {
        ${tw`bg-Hover`}
      }
      &:active:not(:disabled) {
        ${tw`bg-Pressed`}
      }
    }
  }
`;
