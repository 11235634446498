import styled from 'styled-components';
import tw from 'twin.macro';

export const Layout = styled.div`
  & {
    ${tw`flex justify-between py-2 pl-6 pr-5`}
    background-color: ${(props) => props.backgroundColor};
  }
`;

export const StudentDropDownWrapper = styled.button`
  & {
    ${tw`relative flex items-center`}
    p {
      ${tw`text-body-lg-700 text-my-gray-10`}
    }
  }
`;

export const DropdownMenuWrapper = styled.div`
  & {
    ${tw`relative flex justify-end flex-1`}
  }
`;
