import { useUserInfoQuery } from '@apis/query/user';
import { UserType } from '@asset/enum/UserEnum';
import BottomNavigation from '@components/common/BottomNavigation';
import Lnb from '@components/common/nav/Lnb';
import MobileTopNavBar from '@components/common/nav/MobileTopNavBar';
import { useWindowSize } from '@public/hooks/useWindowSize';
import { useRouter } from 'next/router';
import React from 'react';

const GlobalLayout = ({
  children,
  lnbItemList = [],
  snbElement = null,
  contentWrapperRef = null,
  backgroundColor = 'var(--gray-100)',
  hideMobileNav = false,
}: {
  children: React.ReactNode;
  lnbItemList?: { href: string; text: string; pageType: string }[];
  snbElement?: React.ReactNode | null;
  contentWrapperRef?: React.RefObject<HTMLDivElement>;
  backgroundColor?: string;
  hideMobileNav?: boolean;
}) => {
  const router = useRouter();
  const { data: user } = useUserInfoQuery();
  const { isMobileView } = useWindowSize();
  const showLnb = !isMobileView && lnbItemList?.length > 0;
  const showSnb = !isMobileView && snbElement;
  const showMobileNav =
    !hideMobileNav &&
    isMobileView &&
    ((user?.type === UserType.STUDENT &&
      router.asPath.includes('my-study-records')) ||
      (user?.type === UserType.PARENT &&
        !router.asPath.includes('course/detail/')));

  return (
    <>
      {showMobileNav ? (
        <MobileTopNavBar
          backgroundColor={
            router.asPath.includes('my-study-records')
              ? 'var(--gray-95)'
              : 'var(--gray-100)'
          }
        />
      ) : (
        <></>
      )}
      <section className="flex w-full h-full">
        {showSnb ? snbElement : <></>}
        <div
          className="flex flex-col items-center justify-center w-full"
          style={{
            backgroundColor: backgroundColor,
            height: isMobileView
              ? showMobileNav
                ? 'calc(100dvh - var(--height-mobile-nav))'
                : '100%'
              : 'calc(100dvh - var(--height-global-nav))',
            width: showSnb
              ? 'calc(100dvw - var(--width-close-side-bar))'
              : '100%',
          }}
        >
          {showLnb ? <Lnb list={lnbItemList} /> : <></>}
          <section
            className="flex justify-center w-full h-full overflow-y-scroll"
            ref={contentWrapperRef}
            style={{
              height: showLnb ? 'calc(100% - var(--height-local-nav))' : '100%',
            }}
          >
            {children}
          </section>
          {user?.type === UserType.PARENT ? (
            isMobileView && <BottomNavigation />
          ) : (
            <></>
          )}
        </div>
      </section>
    </>
  );
};

export default GlobalLayout;
