import { ComputeEngine } from '@cortex-js/compute-engine';
import {
  AssignmentStudyPurpose,
  StudyStep,
  STUDY_STATUS_KOR,
} from '@asset/enum/problemSolvingEnum';
import { StudyContentType } from '@asset/enum/materialEnum';
import {
  ElementarySchoolMathCourse,
  EtcCourse,
  HighSchoolMathCourse,
  MiddleSchoolMathCourse,
} from '@asset/enum/materialEnum';
import { getMaterialFilterList } from '@apis/api/manageStudyMaterial';
import { ReportContentType, StudyPurpose } from 'constants/enum';
import { ContentValue, getStudyHistories } from '@apis/api/studentStudyContent';
import { PhoneNumberUtil } from 'google-libphonenumber';
import _ from 'lodash';
import { StudyReportSlide } from 'routes/studyReport';
import { DateTime } from 'luxon';

export const translateStudyStatus = (textList) => {
  const studyStatusText = {
    'main-study': '본학습',
    'first-review': '복습1',
    'second-review': '복습2',
    description: '설명하기',
  };
  let result = '';
  textList?.map((text) => {
    result += studyStatusText[text] + ', ';
  });
  return result.slice(0, -2);
};

export const translateStudyStatusStepEngToKor = (engText) => {
  const studyStatusText = {
    'first-solving': STUDY_STATUS_KOR.FIRST_SOLVING,
    'second-solving': STUDY_STATUS_KOR.SECOND_SOLVING,
    'taking-lecture': STUDY_STATUS_KOR.TAKING_LECTURE,
    'review-note': STUDY_STATUS_KOR.REVIEW_NOTE,
    'first-review': STUDY_STATUS_KOR.FIRST_REVIEW,
    'second-review': STUDY_STATUS_KOR.SECOND_REVIEW,
    description: STUDY_STATUS_KOR.DESCRIPTION,
    completed: STUDY_STATUS_KOR.COMPLETED,
  };
  return studyStatusText[engText];
};

export const getSevenStudyStatusStepEnglish = () => {
  return [
    StudyStep.FIRST_SOLVING,
    StudyStep.SECOND_SOLVING,
    StudyStep.TAKING_LECTURE,
    StudyStep.REVIEW_NOTE,
    StudyStep.FIRST_REVIEW,
    StudyStep.SECOND_REVIEW,
    StudyStep.DESCRIPTION,
  ];
};

export const getThreeStudyStatusStepEnglish = () => {
  return [
    StudyStep.FIRST_SOLVING,
    StudyStep.FIRST_REVIEW,
    StudyStep.SECOND_REVIEW,
  ];
};

// [34, 35] 형태의 array 로 오면 => 34-35 형태의 string 반환
export const convertPageListToText = (pageRange, simplify): string => {
  if (simplify) {
    if (pageRange.length > 1) {
      return `${pageRange[0]} ~ ${pageRange[pageRange.length - 1]}`;
    } else {
      return `${pageRange[0]}`;
    }
  }
  const firstPageArray = [];
  const lastPageArray = [];
  let bool = 0;
  let idx = 0;

  firstPageArray.push(parseInt(pageRange[0]));
  while (idx < pageRange.length) {
    const currentNum = parseInt(pageRange[idx]);
    const nextNum = parseInt(pageRange[idx + 1]);
    if (currentNum + 1 === nextNum) {
      if (bool === 0 && firstPageArray.indexOf(currentNum) === -1) {
        firstPageArray.push(currentNum);
      }
      bool = 1;
      idx++;
      continue;
    } else {
      if (bool === 0 && firstPageArray.indexOf(currentNum) === -1) {
        firstPageArray.push(currentNum);
      }
      lastPageArray.push(currentNum);
      bool = 0;
      idx++;
      continue;
    }
    idx++;
  }

  let pageText = '';
  firstPageArray.forEach((element, index) => {
    if (element === lastPageArray[index]) {
      pageText += element + ', ';
    } else {
      pageText += element + '-' + lastPageArray[index] + ', ';
    }
  });

  if (pageText === 'NaN-undefined, ') {
    pageText = '';
  } else if (pageText.length > 2) {
    pageText = pageText.slice(0, -2);
  }

  return pageText;
};

export const convertNumberToTime = (timeNumber, locale = 'ko') => {
  const min = Math.floor(timeNumber / 60);
  const sec = timeNumber % 60;
  return locale == 'ko' ? `${min}분 ${sec}초` : `${min}m ${sec}s`;
};

export const convertTimeTextToSecond = (timeText) => {
  const hms = timeText;
  const a = hms.split(':');
  const seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
  return seconds;
};

export const convertSecondToTimeText = (
  second,
  maxCharLength = 2,
  secondFixNum = 0,
  formatParamObj = {
    hour: '시간',
    minute: '분',
    second: null,
  },
) => {
  const hours = Math.floor(second / 3600);
  const minutes = Math.floor((second - hours * 3600) / 60);
  const seconds = second - hours * 3600 - minutes * 60;

  const hoursString = String(hours).padStart(maxCharLength, '0');
  const minutesString = String(minutes).padStart(maxCharLength, '0');
  const secondsString = String(seconds.toFixed(secondFixNum)).padStart(
    maxCharLength,
    '0',
  );

  const timeString = hoursString + ':' + minutesString + ':' + secondsString;
  let timeFormattingString = '';
  if (formatParamObj?.hour && Number(hoursString) !== 0)
    timeFormattingString += hoursString + formatParamObj?.hour + ' ';
  if (formatParamObj?.minute && Number(minutesString) !== 0)
    timeFormattingString += minutesString + formatParamObj?.minute + ' ';
  if (formatParamObj?.second && Number(secondsString) !== 0)
    timeFormattingString += secondsString + formatParamObj?.second + ' ';

  if (timeFormattingString === '') {
    timeFormattingString = '0' + formatParamObj.hour;
  } else timeFormattingString = timeFormattingString.slice(0, -1);

  return {
    timeString,
    timeFormattingString,
    hoursString,
    minutesString,
    secondsString,
  };
};

export const latexToMathJson = (latex, splitChar = ',') => {
  const ce = new ComputeEngine();
  const list = latex.replaceAll(' ', '').split(splitChar);
  const result = [];
  list.map((value) => {
    if (value) {
      const rawMath: any = ce.parse(value);
      if (!rawMath || !rawMath.json) result.push('');
      let rawMathJson = rawMath?.json;

      const expandMathJson = ce.box(['Expand', rawMath]).evaluate().json;
      if (expandMathJson !== 'False') {
        rawMathJson = expandMathJson;
      }

      if (rawMath?.isValid) {
        if (!Array.isArray(rawMathJson)) {
          rawMathJson = [rawMathJson];
        }
        if (JSON.stringify(rawMathJson).includes('Error')) {
          result.push(['']);
        } else result.push([JSON.stringify(rawMathJson)]);
      } else {
        result.push(['']);
      }
    }
  });
  return result;
};
export const getMathJson = (answerObj, splitChar = ',') => {
  const answerMathJson = {};
  const keyList = Object.keys(answerObj);
  for (let i = 0; i < keyList.length; i++) {
    if (answerObj[keyList[i]]) {
      answerMathJson[keyList[i]] = latexToMathJson(
        answerObj[keyList[i]],
        splitChar,
      );
    } else {
      answerMathJson[keyList[i]] = null;
    }
  }
  return answerMathJson;
};
export const changeMathFieldText = (text) => {
  const result = `${String(text)?.replaceAll(' ', '~')}`;
  return result;
};
export const convertTextToKaTeX = (text: string) => {
  const result = `${String(text)
    ?.replaceAll('~', '\\sim')
    .replaceAll(' ', '~')
    .replaceAll('\\neq', '\\mathrel{\\char`≠}')
    .replaceAll('\\not\\subset', '\\mathrel{\\char`⊄}')
    .replaceAll('\\not\\supset', '\\mathrel{\\char`⊅}')
    .replaceAll('&', '\\&')}`;
  return result;
};
export const getYearList = () => {
  const now = new Date();
  const year = now.getFullYear();
  const list = [];
  for (let index = 9; index >= 0; index--) {
    list.push(year - index);
  }
  return list;
};
export const timeStampToDateTime = (
  timestamp: number,
  timezone: string,
): {
  date: string;
  time: string;
  daysAgo: number;
} => {
  if (_.isNil(timestamp)) return null;
  const targetDate: DateTime = DateTime.fromSeconds(timestamp, {
    zone: timezone,
  });
  const today: DateTime = DateTime.now().setZone(timezone);
  const date: string = targetDate.toFormat('M월 D일');
  const time: string = targetDate.toFormat('HH:mm');

  const todayMidnight = today.startOf('day');
  const targetDateMidnight = targetDate.startOf('day');

  const daysAgo = todayMidnight
    .diff(targetDateMidnight, ['days', 'hours'])
    .toObject().days;

  return { date, time, daysAgo };
};

export const changeKeyName = (
  objectList,
  previousKeyNameList,
  changedKeyNameList,
) => {
  const newList = [];
  objectList.map((valueObject) => {
    const newObj = { ...valueObject };
    previousKeyNameList.map((prevKeyName, keyIndex) => {
      newObj[changedKeyNameList[keyIndex]] = valueObject[prevKeyName];
      delete newObj[prevKeyName];
    });
    newList.push(newObj);
  });
  return newList;
};
export const getHoursFromSeconds = (seconds, length = 1) => {
  const hour = Math.floor(seconds / 3600);
  if (hour > 0) {
    if (length == 1) {
      return hour;
    } else {
      return hour > 9 ? `0${hour}` : hour;
    }
  } else {
    return false;
  }
};

export const getMinutesFromSeconds = (seconds, length = 1) => {
  const hours = Math.floor(seconds / 3600);
  const minute = Math.floor((seconds - hours * 3600) / 60);
  if (minute > 59) {
    if (length == 1) {
      return minute % 60;
    } else {
      return minute % 60 > 9 ? minute % 60 : `0${minute % 60}`;
    }
  } else {
    if (length == 1) {
      return minute;
    } else {
      return minute > 9 ? minute : `0${minute}`;
    }
  }
};
export const getClassificationNameText = (classificationObj) => {
  let text = '';
  if (classificationObj?.chapterIdx) text += `${classificationObj.chapterIdx}.`;
  if (classificationObj?.sectionIdx) text += `${classificationObj.sectionIdx}.`;
  if (classificationObj?.subsectionIdx)
    text += `${classificationObj.subsectionIdx}.`;

  if (classificationObj?.subsectionName)
    text += ` ${classificationObj.subsectionName}`;
  else if (classificationObj?.sectionName)
    text += ` ${classificationObj.sectionName}`;
  else if (classificationObj?.chapterName)
    text += ` ${classificationObj.chapterName}`;

  return text;
};
export const getStartToEndClassificationName = (
  startClassification,
  endClassification,
) => {
  let resultText = '';
  const startClassificationName =
    getClassificationNameText(startClassification);
  const endClassificationName = getClassificationNameText(endClassification);

  if (startClassificationName === endClassificationName) {
    if (startClassificationName === '') {
      resultText = '-';
    } else resultText = startClassificationName;
  } else {
    resultText = `${startClassificationName} ~ ${endClassificationName}`;
  }

  return resultText;
};

export const getPageTypeFromStudyContentType = (type) => {
  if (type === StudyContentType.MY_STUDY_MATERIAL) {
    return StudyContentType.MY_STUDY_MATERIAL;
  } else if (type === StudyContentType.MY_ASSIGNMENT) {
    return StudyContentType.MY_ASSIGNMENT;
  } else if (
    type === StudyContentType.MY_TEST ||
    type === StudyContentType.MY_WRONG_TEST ||
    type === StudyContentType.MY_LEVEL_TEST
  ) {
    return StudyContentType.MY_TEST;
  }
};

export const getMissionPurposeFromCurrStudyStatus = (currStudyStatus) => {
  const originStudyStatusAndMissionStudyStatusObj = {
    [StudyStep.FIRST_SOLVING]: AssignmentStudyPurpose.MAIN_STUDY,
    [StudyStep.SECOND_SOLVING]: AssignmentStudyPurpose.MAIN_STUDY,
    [StudyStep.TAKING_LECTURE]: AssignmentStudyPurpose.MAIN_STUDY,
    [StudyStep.REVIEW_NOTE]: AssignmentStudyPurpose.MAIN_STUDY,
    [StudyStep.FIRST_REVIEW]: AssignmentStudyPurpose.FIRST_REVIEW,
    [StudyStep.SECOND_REVIEW]: AssignmentStudyPurpose.SECOND_REVIEW,
  };
  return originStudyStatusAndMissionStudyStatusObj[currStudyStatus];
};

export const getMissionPurposeTranslationKey = (purpose: StudyPurpose) => {
  const map = {
    [StudyPurpose.MAIN_STUDY]: 'common:StudyPurpose.mainStudy',
    [StudyPurpose.DESCRIPTION]: 'common:StudyPurpose.explain',
  };
  return map[purpose];
};

export const hasMultipleCourse = (studyContentInfo): boolean => {
  if (studyContentInfo) {
    const courseList = [];
    studyContentInfo.materials?.map((material) => {
      if (
        material.course &&
        !courseList.some((course) => course === material.course)
      ) {
        courseList.push(material.course);
      }
    });
    return courseList.length > 1 ? true : false;
  }
  return false;
};

export const getMaterialCourse = (studyContentInfo, t) => {
  return hasMultipleCourse(studyContentInfo)
    ? t('common:course.integrated')
    : studyContentInfo.materials && studyContentInfo.materials[0]?.course
    ? studyContentInfo.materials[0]?.course
    : '';
};

export const sortObjectArrayByCourseOrder = <T,>(
  objectArray: T[],
  courseValueKeyName: string,
): T[] => {
  const courseOrder = [
    ...Object.values(MiddleSchoolMathCourse),
    ...Object.values(HighSchoolMathCourse),
    ...Object.values(ElementarySchoolMathCourse),
    ...Object.values(EtcCourse),
  ];
  objectArray.sort(
    (a, b) =>
      courseOrder.indexOf(a[courseValueKeyName]) -
      courseOrder.indexOf(b[courseValueKeyName]),
  );
  return objectArray;
};

export const getListFiltering = async () => {
  const data = await getMaterialFilterList();

  const filterSubjectList = [
    ...new Set(
      data.map((obj) => {
        return { id: obj['subject'], name: obj['subject'] };
      }),
    ),
  ].filter(
    (arr: any, index, callback) =>
      index === callback.findIndex((t: any) => t.id === arr.id),
  );

  const filterCourseList = [
    ...new Set(
      data.map((obj) => {
        return { id: obj['course'], name: obj['course'] };
      }),
    ),
  ].filter(
    (arr: any, index, callback) =>
      index === callback.findIndex((t: any) => t.id === arr.id),
  );

  return { filterSubjectList, filterCourseList };
};

export const addStudyHistoryInContentValues = async (
  contentValues: ContentValue[],
  studentId: number,
): Promise<ContentValue[]> => {
  const _contentValues = JSON.parse(JSON.stringify(contentValues));
  const pageIds = [];
  _contentValues.map((ps) => pageIds.push(ps.pageId));
  const historiesResponse = await getStudyHistories(pageIds, studentId);
  historiesResponse.histories.map((history, index) => {
    _contentValues[index]['studyHistory'] = history;
  });
  return _contentValues;
};

const phoneUtil = PhoneNumberUtil.getInstance();

export const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

export const phoneNumberFieldValidation = (value: string, required = false) => {
  const isEmpty =
    _.isNil(value) ||
    _.isNil(value) === null ||
    value.replaceAll(' ', '') === '';
  if (
    (required && !isEmpty && isPhoneValid(value)) ||
    (!required && (isEmpty || isPhoneValid(value)))
  )
    return true;
  else return '휴대폰번호를 올바르게 입력해주세요';
};

export const covertCamelToSnake = (str: string) => {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};

export const getReportContentTypeFromReportUrl = (
  pathname: string,
): ReportContentType => {
  if (pathname.includes(StudyReportSlide.STUDY_TIME)) {
    return ReportContentType.STUDY_TIME;
  } else if (pathname.includes(StudyReportSlide.STUDY_RECORDS)) {
    return ReportContentType.STUDY_RECORD;
  } else if (pathname.includes(StudyReportSlide.COURSE_DETAIL)) {
    return ReportContentType.ON_GOING_COURSE;
  } else if (pathname.includes(StudyReportSlide.TEST_RESULTS)) {
    return ReportContentType.TEST_RECORD;
  } else {
    return null;
  }
};

export const urlToFileObject = async (image: string): Promise<File> => {
  const response = await fetch(image);
  const blob = await response.blob();
  const file = new File([blob], 'image.jpg', { type: blob.type });
  return file;
};
