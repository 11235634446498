import styled from 'styled-components';

export const LnbStyle = styled.div`
  & {
    z-index: 5;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--gray-80);
    background-color: inherit;

    ul {
      width: 100%;
      display: flex;
      margin: 0;
      padding-top: 1rem;
      padding-left: 2.5rem;
      gap: 1.5rem;

      > a {
        position: relative;
        padding-bottom: 0.5rem;
        &.picked {
          color: var(--gray-10);
        }
      }

      .disabled-link {
        font-weight: 500;
        color: var(--old-grey-500);
        cursor: default;
      }
      @keyframes pick-sub-bar {
        from {
          bottom: -10px;
        }
        to {
          bottom: -1px;
        }
      }
    }
  }
`;
