import {
  useFilteringStudentsQuery,
  useFilteringTeachersQuery,
} from '@apis/query/listView';
import ManageStudentSideBar from '@components/common/list/SideBar/ManageStudentSideBar';
import GlobalLayout from '@components/dashboard/GlobalLayout';
import MyClassMainPage from '@components/my-class/MyClassMainPage';
import MyClassWeeklyGoalPage from '@components/my-class/MyClassWeeklyGoalPage';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import {
  MY_CLASS_MAIN_URL_PREFIX,
  MY_CLASS_WEEKLY_GOAL_URL_PREFIX,
} from 'routes/common';

export const myClassListKey = {
  STUDENT: 'studentInfo',
  STUDY_STATUS: 'recentStudyStatusInfo',
  ATTENDANCE: 'attendanceInfo',
  MISSION_STATUS: 'assignmentInfo',
  STUDY_TIME_STATUS: 'goalInfo',
  QUESTION: 'helpProblemCount',
  SCORE: 'waitProblemCount',
  WEEKLY_REPORT: 'weeklyStudyReportInfo',
  ATTANDANCE_TIME: 'lastAttendTime',
  TEACHER: 'teacher',
};

export const MY_CLASS_PATH_QUERY = 'myClassType';

export const isRouterHasFilteringQuery = (routerQuery) => {
  const routerQueryList = JSON.parse(JSON.stringify(Object.keys(routerQuery)));
  const pathQueryIndex = routerQueryList.indexOf(MY_CLASS_PATH_QUERY);
  routerQueryList.splice(pathQueryIndex, 1);
  return routerQueryList.length !== 0;
};

const MyClassPage = () => {
  const router = useRouter();
  const [lnbMenu, setLnbMenu] = useState([]);

  const { data: filteringTeachers } = useFilteringTeachersQuery();
  const { data: filteringStudents } = useFilteringStudentsQuery();

  useEffect(() => {
    setLnbMenu([
      {
        href: MY_CLASS_MAIN_URL_PREFIX,
        text: '나의 교실',
        pageType: '',
      },
      {
        href: MY_CLASS_WEEKLY_GOAL_URL_PREFIX,
        text: '목표 달성 관리',
        pageType: '',
      },
    ]);
  }, [router]);

  return (
    <GlobalLayout lnbItemList={lnbMenu} snbElement={<ManageStudentSideBar />}>
      <section className={`py-6 px-10 w-full`}>
        {router?.query?.[MY_CLASS_PATH_QUERY] &&
        router?.query?.[MY_CLASS_PATH_QUERY][0] === 'main' ? (
          <MyClassMainPage
            filteringStudentList={filteringStudents}
            filteringTeacherList={filteringTeachers}
          ></MyClassMainPage>
        ) : (
          <MyClassWeeklyGoalPage
            filteringStudentList={filteringStudents}
            filteringTeacherList={filteringTeachers}
          ></MyClassWeeklyGoalPage>
        )}
      </section>
    </GlobalLayout>
  );
};

export default MyClassPage;
